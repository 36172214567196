
.account-overview-item .aoi-data span{
    display: none !important;
} 

/* --- side menu min css start --- */
@media screen and (min-width:1200px) {

    .side-menu-bx .smb-menu-bx{
        height: calc(100% - 70px) !important;
        max-height: calc(100% - 70px) !important;
    }
}