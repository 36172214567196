/* --- Loader css start --- */
html[data-theme=light-theme] .web-preloader {
    background-color: var(--snow-gray);
}
/* --- Loader css End --- */



/* --- Header css start --- */
html[data-theme=light-theme] .custom-header{
    background-color: var(--snow-gray);
    border-bottom-color: var(--black-060);
    box-shadow: 0 0 10px var(--white-200);
}


/* Header Logo Start */
html[data-theme=light-theme] .custom-header .ch-inner .ch-left .ch-logo-bx .logo-text{
    filter: invert();
}
/* Header Logo End */

/*  */
html[data-theme=light-theme] .cr-theme-btn .cr-tb-lable{
    color: var(--dark3);
    font-weight: 500;
}

/* ---  Header css (cr icons link start) --- */
html[data-theme=light-theme] .cr-icons-link{
    color: var(--dark3);
    background-color: var(--white);
    border: 1px solid var(--black-060);
}

html[data-theme=light-theme] .cr-drop-item:hover > .cr-icons-link,
html[data-theme=light-theme] .cr-icons-link:hover{
    color: var(--white);
    background-color: var(--emerald-green);
}
/* ---  Header css (cr icons link end) --- */


/* ---  Header css (Aside Menu Icon Start) --- */
html[data-theme=light-theme] .menu-icon-bx {
    background-color: var(--white);
    border: 1px solid var(--black-060);
}

html[data-theme=light-theme] .menu-icon-bx span {
    opacity: 1;
    background-color: var(--slate-grey4);
}

html[data-theme=light-theme] .menu-icon-bx:hover span,
html[data-theme=light-theme] .menu-icon-bx.active span{
    opacity: 1;
    background-color: var(--dark3);
}
/* ---  Header css (Aside Menu Icon end) --- */


/* ---  Header css (smb min btn start) --- */
html[data-theme=light-theme] .smb-min-btn{
    color: var(--dark3);
    background-color: var(--white);
    border: 1px solid var(--black-060);
}

html[data-theme=light-theme] .smb-min-btn:hover{
    color: var(--white);
    background-color: var(--emerald-green);
    border-color: var(--emerald-green);
}
/* ---  Header css (smb min btn end) --- */

/* --- Header css end --- */



/* --- Sidemenu css start --- */
html[data-theme=light-theme] .side-menu-bx{
    background-color: var(--snow-gray);
    border-right: 1px solid var(--black-060);
}

/* --- */
html[data-theme=light-theme] .side-menu-bx .smb-header{
    background-color: var(--snow-gray);
}

html[data-theme=light-theme] .side-menu-bx .smb-header .smb-logo .logo-text{
    filter: invert(1);
}
/* --- */

/* --- */
html[data-theme=light-theme] .side-menu-bx .smb-link{
    color: var(--slate-grey4);
    font-weight: 600;
}

html[data-theme=light-theme] .side-menu-bx .smb-link:hover{
    color: var(--dark3);
    background-color: var(--white);
}

html[data-theme=light-theme] .side-menu-bx .smb-link.active{
    color: var(--dark3);
    background: var(--white);
    box-shadow: 0 0 0 1px var(--black-040);
}

html[data-theme=light-theme] .side-menu-bx .smb-link.highlight-link{
    box-shadow: 0 0 0 1px var(--black-040);
}

html[data-theme=light-theme] .side-menu-bx .smb-link.highlight-link:hover{
    color: var(--white);
    background: var(--emerald-green2);
}

@media screen and (max-width:1200px) and (min-width:0px) {
    html[data-theme=light-theme] .side-menu-bx .smb-link.highlight-link{
        color: var(--white);
        background: var(--emerald-green2);
    }
}
/* --- */

/* --- Sidemenu css end --- */



/* --- Flash message start --- */
html[data-theme=light-theme] .flash-message{
    border: none;
    background-color: var(--white);   
}

html[data-theme=light-theme] .flash-message .flash-text {
    color: var(--black);
}
/* --- Flash message end --- */



/* --- Style css start ---- */
html[data-theme=light-theme] a, html[data-theme=light-theme] a:hover {
    color: var(--black);
}



/* --- Body tag start --- */
html[data-theme=light-theme] body {
    color: var(--black);
    background-color: var(--snow-gray);
}
/* --- Body tag end --- */


/* --- Skeletant css Start --- */
html[data-theme=light-theme] .skeletant-design {
    background-color: rgb(245 245 245);
    background-image: linear-gradient(45deg, rgb(245 245 245) 30%, var(--black-060) 50%, rgb(245 245 245) 70%);
}
/* --- Skeletant css end --- */

/* --- Common color status bx start --- */
html[data-theme=light-theme] .status-white {
    color: var(--dark3) !important;
}

html[data-theme=light-theme] .status-aqua2 {
    color: var(--emerald-green3) !important;
}
/* --- Common color status bx end --- */



/* --- Screen loader start --- */
html[data-theme=light-theme] .screen-loader-bx {
    background-color: var(--snow-gray);
}
/* --- Screen loader end --- */



/* --- Breadcrumb Start --- */
html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link:hover,
html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link.breadcrumb-active,
html[data-theme=light-theme] .breadcrumb-heading-bx {
    color: var(--dark3);
}

html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-sapretor svg,
html[data-theme=light-theme] .breadcrumb-bx .breadcrumb-link {
    color: var(--slate-grey4);
}
/* --- Breadcrumb End --- */



/* --- Common Box Start --- */
html[data-theme=light-theme] .common-box.cb-color2,
html[data-theme=light-theme] .common-box {
    box-shadow: 0 0 0 1px var(--black-040);
    background-color: var(--white);
}

html[data-theme=light-theme] .common-box.cb-all-none {
    box-shadow: none;
    border: none;
    background-color: transparent;
    padding: 0;
}

html[data-theme=light-theme] .common-section-heading,
html[data-theme=light-theme] .common-box-heading {
    color: var(--dark3);
    font-weight: 500;
}

html[data-theme=light-theme] .common-box-text {
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .common-section-separator {
    background-color: var(--black-040);
}
/* --- Common Box End --- */



/* --- Common btn start --- */

/* --- --- */
html[data-theme=light-theme] .cb-eg3-fill{
    color: var(--white);
}

html[data-theme=light-theme] .cwf-am-hover:hover{
    color: var(--white);
}

/* --- --- */
html[data-theme=light-theme] .cb-white-outline{
    color: var(--white);
}

html[data-theme=light-theme] .cb-wb1-outline{
    color: var(--dark3);
    border-color: var(--black-060);
}

html[data-theme=light-theme] .cwo-hover:hover{
    color: var(--black);
    background-color: var(--white);
    border-color: var(--white);
}
/* --- Common btn end --- */



/* --- Common box info btn start --- */
html[data-theme=light-theme] .common-box-info {
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .common-box-info:hover {
    color: var(--emerald-green3);
}
/* --- Common box info btn end --- */



/* --- Calendar navigation start --- */
html[data-theme=light-theme] .calendar-navigation-bx .cnb-btn{
    color: var(--dark3);
    background-color: var(--white);
    box-shadow: 0 0 0 1px var(--black-060);
}

@media screen and (min-width:768px) { 
    html[data-theme=light-theme] .calendar-navigation-bx .cnb-btn:hover{
        color: var(--white);
        box-shadow: 0 0 0 1px var(--emerald-green3);
        background-color: var(--emerald-green3);
    }
}

@media screen and (max-width:767px) and (min-width:0px) { 
    html[data-theme=light-theme] .calendar-navigation-bx .cnb-btn:active{
        color: var(--white);
        box-shadow: 0 0 0 1px var(--emerald-green3);
        background-color: var(--emerald-green3);
    }
}
/* --- Calendar navigation end --- */



/* --- Bootsrep modal start --- */
html[data-theme=light-theme] .custom-content .modal-content {
    border: 1px solid var(--white);
    background-color: var(--white);
}

html[data-theme=light-theme] .custom-modal-header .cmh-lable {
    color: var(--black);
}

html[data-theme=light-theme] .custom-modal-header .cmh-sub-lable {
    color: var(--black-700);
    font-weight: 500;
}

html[data-theme=light-theme] .custom-modal-body {
    padding: 0 15px;
}
/* --- Bootsrep modal end --- */



/* --- Close btn start --- */
html[data-theme=light-theme] .close-icon {
    color: var(--black);
}

@media screen and (min-width:700px) {
    html[data-theme=light-theme] .close-icon:hover {
        background-color: var(--black-100);
    }
}

@media screen and (max-width:700px) and (min-width:0px) {
    html[data-theme=light-theme] .close-icon:active {
        background-color: var(--black-100);
    }
}
/* --- Close btn end --- */



/* --- Common data bx start --- */
html[data-theme=light-theme] .common-data-bx {
    background-color: var(--black-040);
    border: 1px solid var(--black-060);
}

html[data-theme=light-theme] .common-data-bx .cdb-item {
    color: var(--dark3);
}

html[data-theme=light-theme] .common-data-bx .cdb-item .cdb-label {
    color: var(--slate-grey4);
    font-weight: 600;
}

html[data-theme=light-theme] .common-data-bx .cdb-item .cdb-data {
    font-weight: 600;
}
/* --- Common data bx end --- */



/* --- Common tabs bx Start --- */
html[data-theme=light-theme] .common-tabs-bx::after {
    background-color: var(--black-060);
}

html[data-theme=light-theme] .common-tabs-bx .ctb-item {
    color: var(--black-700);
}

html[data-theme=light-theme] .common-tabs-bx .ctb-item:hover,
html[data-theme=light-theme] .common-tabs-bx .ctb-item.active {
    color: var(--black);
}
/* --- Common tabs bx end --- */



/* --- Common drop bx start --- */
html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner{
    border:1px solid var(--black2-060);
    background-color: var(--black2-040);
}

html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-track {
    background-color: var(--black2-040);
}

html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb {
    background-color: var(--black-300);
}

html[data-theme=light-theme] .common-drop-item .common-drop-bx .common-drop-inner::-webkit-scrollbar-thumb:hover {
    background-color: var(--dark3);
}
/* --- Common drop bx end--- */



/* --- Common drop data start --- */
html[data-theme=light-theme] .cdi-current{
    background-color: var(--black-040);
    border-color: var(--black-060);
    color: var(--dark3);
}

html[data-theme=light-theme] .cdi-item{
    color: var(--slate-grey4);
    font-weight: 600;
}

html[data-theme=light-theme] .cdi-item:hover{
    color: var(--dark3);
    background-color: var(--black-100);
}

html[data-theme=light-theme] .cdi-item.cdi-seleted{
    color: var(--white);
    background-color: var(--emerald-green3);
}
/* --- Common drop data end --- */



/* --- Social icons end --- */
html[data-theme=light-theme] .sib-style-2 .sib-item{
    background-color: var(--black-040);
    border: 1px solid var(--black-060);
}
/* --- Social icons start --- */



/* --- Chart design start--- */
html[data-theme=light-theme] .Mycharts .MuiChartsLegend-series text,
html[data-theme=light-theme] .Mycharts .MuiChartsAxis-label {
    fill: var(--black) !important;
}

html[data-theme=light-theme] .Mycharts .MuiChartsAxisHighlight-root,
html[data-theme=light-theme] .Mycharts .MuiChartsGrid-horizontalLine{
    stroke: var(--black-200) !important;
}

html[data-theme=light-theme] .Mycharts .MuiChartsAxis-tickLabel {
    font-weight: 500 !important;
}

html[data-theme=light-theme] .Mycharts .css-1f57y8b {
    fill: var(--slate-grey4);
    color: var(--slate-grey4);
}
/* --- Chart design end --- */



/* --- Common progress start --- */
html[data-theme=light-theme] .common-progress-bar {
    background-color: var(--black-060);
}
/* --- Common progress end --- */



/* --- Meter chart start --- */
html[data-theme=light-theme] .meter-chart-bx .mcb-data-bx,
html[data-theme=light-theme] .meter-chart-bx .mcb-data-bx .mcb-label{
    font-weight: 600;
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .meter-chart-bx .mcb-data-bx .mcb-value{
    color: var(--slate-grey4);
}
/* --- Meter chart end --- */



/* --- Arc chat start --- */
html[data-theme=light-theme] .arc-item .arc-name{
    font-weight: 500;
}

html[data-theme=light-theme] .arc-item .arc-check{
    color: #FFB024;
    box-shadow: 0 0 0 1px #FFB024;
    background-color: var(--white);
}
/* --- Arc chat end --- */



/* --- Custome table Start --- */
html[data-theme=light-theme] .custom-table-responsive {
    border: 1px solid var(--black-060);
}

html[data-theme=light-theme] .custom-table thead {
    background-color: var(--black-040);
}

html[data-theme=light-theme] .custom-table thead tr th {
    color: var(--dark3);
    font-weight: 700;
}

/* Table thead (Sorting icons) start */
html[data-theme=light-theme] .custom-table thead tr th .th-sort-icons {
    color: var(--slate-grey4);
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .custom-table thead tr th:hover .th-sort-icons,
html[data-theme=light-theme] .custom-table thead tr th.active .th-sort-icons {
    color: var(--dark3);
}

html[data-theme=light-theme] .custom-table thead tr th.asc .th-sort-icons svg:nth-child(1),
html[data-theme=light-theme] .custom-table thead tr th.desc .th-sort-icons svg:nth-child(2) {
    color: var(--emerald-green3);
}
/* Table thead (Sorting icons) end */

html[data-theme=light-theme] .custom-table:not(.off-tr-bg) tbody tr {
    border-top: 1px solid var(--black-060);
}

html[data-theme=light-theme] .custom-table:not(.off-tr-bg) tbody tr:nth-child(odd) {
    background-color: var(--white);
}

html[data-theme=light-theme] .custom-table:not(.off-tr-bg) tbody tr:nth-child(even) {
    background-color: var(--black-040);
}

html[data-theme=light-theme] .custom-table:not(.off-tr-bg) tbody tr:hover {
    background-color: var(--snow-gray);
}

html[data-theme=light-theme] .custom-table tbody tr td {
    font-weight: 500;
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .custom-table tbody tr td .td-user .user-name{
    color: var(--dark3);
}
/* --- Custome table end --- */



/* --- Pagenation start --- */
html[data-theme=light-theme] .pagination-bx .pb-next,
html[data-theme=light-theme] .pagination-bx .pb-prev{
    color: var(--dark3);
    background-color: var(--white);
    border-color: var(--black-100);
    font-weight: 600;
}

html[data-theme=light-theme] .pb-number-bx .pnb-item{
    color: var(--slate-grey4);
    font-weight: 600;
}

html[data-theme=light-theme] .pb-number-bx .pnb-item:not(.pnb-current, .pnb-disabled):hover{
    color: var(--dark3);
    background-color: var(--white);
    border: var(--black-100);
}

html[data-theme=light-theme] .pb-number-bx .pnb-item.pnb-current{
    color: var(--dark3);
    background-color: var(--black-040);
    border: var(--black-100);
}
/* --- Pagenation end --- */



/* --- Empty box css start ---*/
html[data-theme=light-theme] .empty-div-bx.edb-border-top{
    border-top-color: var(--black-060);
}

html[data-theme=light-theme] .empty-div-bx .empty-heading {
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme] .empty-div-bx .empty-sub-heading {
    font-weight: 500;
    color: var(--slate-grey4);
}
/*--- Empty box css end ---*/



/* --- Notice Start--- */
html[data-theme=light-theme] .legal-content .legal-heading {
    color: var(--dark3);
}

html[data-theme=light-theme] .legal-content ul li,
html[data-theme=light-theme] .legal-content p {
    color: var(--slate-grey4);
    font-weight: 500;
}
/* --- Notice End --- */



/* --- Cdi Account start --- */
html[data-theme=light-theme] .cdi-account .cdi-current{
    border-color: var(--black-060);
    background-color: var(--white);
}
/* --- Cdi Account end --- */



/* --- Challenge Card Bx Start --- */
html[data-theme=light-theme] .challenge-card-bx {
    background: var(--emerald-green3);
}

html[data-theme=light-theme] .challenge-card-bx .ccb-inner{
    background-color: var(--white-100);
}

html[data-theme=light-theme] .ccb-request-info {
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .ccb-request-info.ccb-real-ac {
    color: var(--emerald-green3);
    border-color: var(--emerald-green3);
}
/* --- Challenge card bx end --- */



/* --- Challenge credentials bx start --- */
html[data-theme=light-theme] .challenge-credentials-bx {
    border: 1px solid var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .challenge-credentials-bx .credential-item .credential-label {
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .challenge-credentials-bx .credential-item .credential-data {
    color: var(--black);
}

html[data-theme=light-theme] .challenge-credentials-bx .credential-item .credential-data .credential-icon {
    color: var(--black-800);
    border: 1px solid var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .challenge-credentials-bx .credential-item .credential-data .credential-icon:hover {
    background-color: var(--emerald-green3);
    border-color: var(--emerald-green3);
    color: var(--white);
}
/* --- Challenge credentials bx end --- */



/* --- Account overview filter start --- */
html[data-theme=light-theme] .aof-chart-filter{
    background-color: var(--black-040);
    box-shadow: 0 0 0 1.5px var(--black-060);
}

html[data-theme=light-theme] .aof-chart-filter:after{
    background-color: var(--white);
}

html[data-theme=light-theme] .aof-chart-filter .acf-item{
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .aof-chart-filter .acf-item:hover,
html[data-theme=light-theme] .aof-chart-filter.lineChart .acf-item:nth-child(1),
html[data-theme=light-theme] .aof-chart-filter.barChart .acf-item:nth-child(2){
    color: var(--dark3);
}
/* --- Account overview filter end --- */



/* --- Account overview css start --- */
html[data-theme=light-theme] .account-overview-item:not(:last-child):after{
    background-color: var(--black-060);
}

html[data-theme=light-theme] .account-overview-item .aoi-label {
    color: var(--slate-grey4);
    font-weight: 500;
}

html[data-theme=light-theme] .account-overview-item .aoi-data {
    color: var(--dark3);
    font-weight: 500;
}

html[data-theme=light-theme] .account-overview-item .aoi-data span{
    color: var(--dark3);
    background-color: var(--black-040);
    font-weight: 600;
}

html[data-theme=light-theme] .account-overview-item .aoi-data.aoi-status-red,
html[data-theme=light-theme] .account-overview-item .aoi-data span.aoi-status-red {
    color: var(--coral-orange);
}

html[data-theme=light-theme] .account-overview-item .aoi-data.aoi-status-green,
html[data-theme=light-theme] .account-overview-item .aoi-data span.aoi-status-green {
    color: var(--emerald-green3);
}
/* --- Account overview css end --- */



/* --- Account detail page start --- */

/* Account detail (account info bx) start */
html[data-theme=light-theme] .account-info-bx .aib-inner-bx .aib-i-data .aib-i-name {
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme] .account-info-bx .aib-inner-bx .aib-i-data .aib-i-created {
    color: var(--slate-grey4);
    font-weight: 500;
}
/* Account detail (account info bx) end */


/* Account detail (account basic info) start */
html[data-theme=light-theme] .account-basic-info .abi-item .abi-label {
    color: var(--dark3-700);
    font-weight: 500;
}

html[data-theme=light-theme] .account-basic-info .abi-item .abi-label svg {
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .account-basic-info .abi-item .abi-data {
    color: var(--dark3);
    font-weight: 500;
}

html[data-theme=light-theme] .account-basic-info .abi-item .abi-data.abi-highlight{
    color: var(--aqua-mint2);
    font-size: 20px;
}

html[data-theme=light-theme] .account-basic-info .abi-item .abi-data span {
    color: var(--majestic-purple);
    background-color: #F4F3FF;
}
/* Account detail (Account basic info) end */


/* Account detail (Limit Card item) start */
html[data-theme=light-theme] .limit-card-bx {
    background-color: var(--black-040);
    border: none;
}

html[data-theme=light-theme] .limit-card-bx .lc-heading {
    color: var(--dark3);
}

html[data-theme=light-theme] .limit-card-bx .limit-card-inner .lci-left-amount {
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme] .limit-card-bx .limit-card-inner .lci-left-amount span{
    font-weight: 500;
}

html[data-theme=light-theme] .limit-card-bx .limit-card-inner .lci-percent {
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme] .limit-card-bx .limit-card-inner .lci-data-bx {
    color: var(--slate-grey4);
    font-weight: 500;
}

html[data-theme=light-theme] .limit-card-bx .limit-card-inner .lci-data-bx .ldb-item .li-data{
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme] .limit-card-inner .arc-item .arc-value{
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .limit-card-inner .arc-item .arc-other-value{
    color: var(--slate-grey4);
}
/* Account detail (Limit Card item) end */


/* Account detail (Calender bx) start */
html[data-theme=light-theme] .calender-bx{
    border-color: var(--black-060);
}

html[data-theme=light-theme] .calander-days .cd-item,
html[data-theme=light-theme] .calander-weeks .cw-item{
    color: var(--slate-grey4);
    border-top-color: var(--black-060);
    border-right-color: var(--black-060);
    font-weight: 600;
}

html[data-theme=light-theme] .calander-days .cd-item .cd-i-date{
    border: 1px solid var(--black-060);
    background-color: var(--white);
}

html[data-theme=light-theme] .calander-days .cd-item .cd-i-event{
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .calander-days .cd-item:not(.cd-i-disabled, .cd-i-seleted):hover{
    background-color: var(--black-040);
}

html[data-theme=light-theme] .calander-days .cd-item:not(.cd-i-disabled, .cd-i-seleted):hover .cd-i-event{
    color: var(--dark3);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-red .cd-i-date{
    background-color: var(--red-color);
    border-color: var(--red-color);
    color: var(--white);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-green .cd-i-date{
    background-color: var(--green-color);
    border-color: var(--green-color);
    color: var(--white);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-disabled .cd-i-event,
html[data-theme=light-theme] .calander-days .cd-item.cd-i-disabled .cd-i-date{
    opacity: 0.8;
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-seleted{
    box-shadow: 0 0 0px 1px var(--yellow-color) inset;
    background-color: var(--black-040);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-seleted{
    box-shadow: 0 0 0px 1px var(--yellow-color) inset;
    background-color: var(--black-040);
}

html[data-theme=light-theme] .calander-days .cd-item.cd-i-seleted .cd-i-event{
    color: var(--dark3);
}
/* Account detail (Calender bx) end */


/* Account detail (Scale overview item) start */
html[data-theme=light-theme] .scale-overview-item .common-progress-bar {
    background-color: var(--black-060);
    box-shadow: none;
}
/* Account detail (Scale overview item) end */

/* --- Account detail page end --- */



/* --- New Challenge page start --- */

/* --- New Challenge (Challenge steps bx) start --- */
html[data-theme=light-theme] .challenge-steps-bx .csb-item .csb-i-num {
    color: var(--slate-grey4);
    box-shadow: 0 0 0 1px var(--black-060);
    font-weight: 600;
}

html[data-theme=light-theme] .challenge-steps-bx .csb-item .csb-i-label {
    color: var(--slate-grey4);
    font-weight: 500;
}

html[data-theme=light-theme] .challenge-steps-bx .csb-item .csb-progress-bar {
    background-color: var(--black-100);
}

html[data-theme=light-theme] .challenge-steps-bx .csb-item.complated .csb-i-label,
html[data-theme=light-theme] .challenge-steps-bx .csb-item.active .csb-i-label {
    color: var(--dark3);
}

html[data-theme=light-theme] .challenge-steps-bx .csb-item.complated .csb-i-num,
html[data-theme=light-theme] .challenge-steps-bx .csb-item.active .csb-i-num {
    color: var(--white);
    background-color: var(--emerald-green3);
    box-shadow: 0 0 0 1px var(--emerald-green3);
}
/* --- New Challenge (Challenge steps bx) end --- */


/* --- New Challenge (Challenge Detail) start --- */
html[data-theme=light-theme] .challenge-detail .cd-heading {
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme] .cd-trader{
    background-color: transparent;
}

html[data-theme=light-theme] .cd-rules-info .cd-r-item {
    color: var(--slate-grey4);
    font-weight: 600;
}

html[data-theme=light-theme] .cd-rules-info .cd-r-item svg {
    background-color: var(--black-040 );
}

html[data-theme=light-theme] .challenge-detail .cd-more-info {
    color: var(--slate-grey4);
    font-weight: 600;
}

html[data-theme=light-theme] .challenge-detail .cd-more-info a {
    color: var(--emerald-green3);
    font-weight: 600;
}

html[data-theme=light-theme] .challenge-detail .cd-more-info a:hover {
    color: var(--emerald-green5);
}
/* --- New Challenge (Challenge Detail) end --- */


/* --- New Challenge (Challenge trading rules) start --- */
html[data-theme=light-theme] .challenge-trading-rules {
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .challenge-trading-rules .ctr-item {
    color: var(--slate-grey4);
    font-weight: 500;
}

html[data-theme=light-theme] .challenge-trading-rules .ctr-item svg {
    background-color: var(--black-040);
}
/* --- New Challenge (Challenge trading rules) end --- */


/* --- New Challenge (Payment method item) end --- */
html[data-theme=light-theme] .payment-method-item {
    border-color: var(--black-040);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .payment-method-item .pmi-data-bx .pmi-heading {
    color: var(--dark3);
    font-weight: 500;
}

html[data-theme=light-theme] .payment-method-item .pmi-data-bx .pmi-pera {
    color: var(--black-700);
}

html[data-theme=light-theme] .payment-method-item:hover {
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}
/* --- New Challenge (Payment method item) end --- */


/* --- New Challenge (Payment tab bx) start --- */
html[data-theme=light-theme] .payment-tab-item {
    font-weight: 500;
    color: var(--black-700);
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .payment-tab-item:hover{
    color: var(--black);
    border-color: var(--black-200);
}

html[data-theme=light-theme] .payment-tab-item.active{
    color: var(--dark3);
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}
/* --- New Challenge (Payment tab bx) end --- */


/* --- New Challenge (Payment data bx) start --- */
html[data-theme=light-theme] .payment-data-bx.pdb-style-3,
html[data-theme=light-theme] .payment-data-bx.pdb-style-2 {
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-lable {
    color: var(--slate-grey4);
    font-weight: 500;
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data {
    color: var(--dark3);
    font-weight: 500;
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon {
    color: var(--black-700);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data .pdb-i-icon:hover {
    color: var(--emerald-green2);
}

html[data-theme=light-theme] .payment-data-bx .pdb-item .pdb-i-data strong {
    color: var(--emerald-green2);
    font-weight: 500;
}
/* --- New Challenge (Payment data bx) end --- */


/* --- New Challenge (Payment details) start --- */
html[data-theme=light-theme] .challenge-payment-details .cpd-item {
    border-bottom: 1px solid var(--black-060);
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item:last-child {
    border-bottom: none;
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item .cpd-title {
    color: var(--dark3);
    font-weight: 500;
    opacity: 0.6;
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item .cpd-amount {
    color: var(--dark3);
    font-weight: 500;
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item.cpd-gt-item .cpd-title {
    color: var(--dark3);
    opacity: 1;
}

html[data-theme=light-theme] .challenge-payment-details .cpd-item.cpd-gt-item .cpd-amount{
    color: var(--emerald-green2);
    font-weight: 600;
}
/* --- New Challenge (Payment details) end --- */

/* --- New Challenge page end --- */



/* --- Leaderboard page start --- */

/* Leaderboard (Best in today) start */
html[data-theme=light-theme] .best-today-item .bti-inner::before{
    background: linear-gradient(180deg, var(--snow-gray3) 0%, var(--white) 100%);
}

html[data-theme=light-theme] .best-today-item .bti-inner::after{
    background: linear-gradient(180deg, var(--snow-gray2) 0%, var(--snow-gray) 93.67%);
}

html[data-theme=light-theme] .best-today-item .bti-badge-img{
    background: radial-gradient(69.52% 69.52% at 50% 69.52%, var(--snow-gray3) 0%, var(--white) 100%);
}

html[data-theme=light-theme] .best-today-item .bti-type{
    color: var(--dark3);
}

html[data-theme=light-theme] .best-today-item .bti-profit{
    color: var(--emerald-green3);
}

html[data-theme=light-theme] .best-today-item .bti-balance{
    color: var(--slate-grey4);
}
/* Leaderboard (Best in today) end */


/* Leaderboard (Best in type) start */
html[data-theme=light-theme] .best-type-bx{
    background-color: var(--white);
}

html[data-theme=light-theme] .best-type-bx .btb-heading{
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .best-type-bx .btb-user-bx .btb-name{
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme]  .best-type-bx .btb-user-bx .btb-amount{
    color: var(--slate-grey4);
    font-weight: 500;
}

html[data-theme=light-theme] .best-type-bx .btb-data{
    color: var(--emerald-green3);
}
/* Leaderboard (Best in type) end */


/* Leaderboard (Best Accounts In Profi) start */
html[data-theme=light-theme] .tr-leaderboard{
    border-radius: 0px;
    border: none;
}

html[data-theme=light-theme] .ct-leaderboard thead{
    background-color: transparent;
}

html[data-theme=light-theme] .ct-leaderboard thead th{
    border-bottom: 1px solid var(--black-060);
}

html[data-theme=light-theme] .ct-leaderboard tbody tr:nth-child(even) td{
    background-color: var(--black-040);
}

html[data-theme=light-theme] .ct-leaderboard tbody tr:hover td{
    background-color: var(--black-100);
}
/* Leaderboard (Best Accounts In Profi) end */

/* --- Leaderboard page end --- */



/* --- Calendar page start --- */

/* Calendar (filter) start */
html[data-theme=light-theme] .prediction-filter-bx .pfb-drop-bx .pfb-drop-item .cdi-current {
    font-weight: 500;
    background-color: var(--snow-gray);
}
/* Calendar (filter) end */

/* Calendar (prediction table) start */
html[data-theme=light-theme] .tr-prediction{
    border-top-color: var(--black-060);
}

html[data-theme=light-theme] .ct-prediction tbody tr:not(:last-child) td{
    border-bottom-color: var(--black-060);
}

html[data-theme=light-theme] .ct-prediction tbody tr td.td-ctp-time::after{
    background-color: var(--white);
    border-right-color: var(--black-060);
}

html[data-theme=light-theme] .ct-prediction .ctp-time{
    color: var(--slate-grey4);
    font-weight: 600;
}

html[data-theme=light-theme] .prediction-card-item{
    background-color: var(--black-040);
    box-shadow: 0 0 0 1px var(--black-060);
}

html[data-theme=light-theme] .prediction-card-item:not(.pci-full):hover{
    background-color: var(--snow-gray);
}

html[data-theme=light-theme] .prediction-card-item .pci-event,
html[data-theme=light-theme] .prediction-card-item .pci-currency{
    font-weight: 600;
    color: var(--emerald-green3);
}

html[data-theme=light-theme] .prediction-card-item .pci-event{
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme] .prediction-card-item .pci-separator{
    background-color: var(--black-060);
}

html[data-theme=light-theme] .prediction-card-item .pci-data{
    font-weight: 500;
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .prediction-card-item .pci-data span{
    color: var(--dark3);
    font-weight: 600;
}
/* Calendar (prediction table) end */

/* --- Calendar page end --- */



/* --- Affiliate data item start --- */

/* Affiliate (affiliate program bx) start */
html[data-theme=light-theme] .affiliate-program-bx .atb-heading{
    font-weight: 500;
}
/* Affiliate (affiliate program bx) end */


/* Affiliate (Data item) start */
html[data-theme=light-theme] .affiliate-data-item{
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .affiliate-data-item .adi-heading{
    color: var(--slate-grey4);
    font-weight: 600;
}

html[data-theme=light-theme] .affiliate-data-item .adi-data{
    color: var(--dark3);
}
/* Affiliate (Data item) end */


/*  */
html[data-theme=light-theme] .affiliate-balance{
    border: 1px solid var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .affiliate-balance .ab-balance,
html[data-theme=light-theme] .affiliate-balance .ab-name{
    color: var(--slate-grey4);
    font-weight: 500;
    opacity: 1;
}

html[data-theme=light-theme] .affiliate-balance .ab-balance{
    color: var(--emerald-green3);
    font-weight: 600;
}
/*  */

/* --- Affiliate page end --- */

/* --- Style css end ---- */



/* --- Commonform css start --- */
html[data-theme=light-theme] ::placeholder{
    color: var(--slate-grey4);
}


/* --- Extra label start --- */
html[data-theme=light-theme] .sgr-outer-label{
    color: var(--black);
    font-weight: 600;
}
/* --- Extra label end --- */



html[data-theme=light-theme] .signinup-group select,
html[data-theme=light-theme] .signinup-group textarea,
html[data-theme=light-theme] .signinup-group input {
    color: var(--black);
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .sg-form-white .signinup-group textarea:focus,
html[data-theme=light-theme] .sg-form-white .signinup-group select:focus,
html[data-theme=light-theme] .sg-form-white .signinup-group input:focus,
html[data-theme=light-theme] .signinup-group textarea:focus,
html[data-theme=light-theme] .signinup-group select:focus,
html[data-theme=light-theme] .signinup-group input:focus {
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}

html[data-theme=light-theme] .signinup-group .group_right_icon,
html[data-theme=light-theme] .signinup-group .group_left_icon{
    color: var(--black);
}

html[data-theme=light-theme] .signinup-group.sgl-sg3 svg{
    color: var(--slate-grey3);
}


/* --- Error input color start --- */
html[data-theme=light-theme] .sg-form-white .signinup-group.error-group textarea,
html[data-theme=light-theme] .sg-form-white .signinup-group.error-group select,
html[data-theme=light-theme] .sg-form-white .signinup-group.error-group input,
html[data-theme=light-theme] .signinup-group.error-group textarea,
html[data-theme=light-theme] .signinup-group.error-group select,
html[data-theme=light-theme] .signinup-group.error-group input{
    border-color: var(--red-color);
    box-shadow: 0 0 0px 1px var(--red-color);
}
/* --- Error input color end --- */


/* --- Form error msg start --- */
html[data-theme=light-theme] .form-error-msg{
    font-weight: 500;
}
/* --- Form error msg end --- */



/* --- Basic input note start --- */
html[data-theme=light-theme] .input-note{
    color: var(--black-700);
    font-weight: 500;
}

html[data-theme=light-theme] .input-note span{
    color: var(--dark3);
    font-weight: 600;
}

html[data-theme=light-theme] .input-note span:hover{
    color: var(--emerald-green3);
}

html[data-theme=light-theme] .input-note svg{
    color: var(--emerald-green3);
}
/* --- Basic input note end --- */


/* --- Remember input checkbox start --- */
html[data-theme=light-theme] .signinup-group-checkmark label{
    color: var(--slate-grey4);
    font-weight: 500;
}

html[data-theme=light-theme] .signinup-group-checkmark:not(.sgc-ci-hover-off) label:hover{
    color: var(--dark3);
}

html[data-theme=light-theme] .signinup-group-checkmark label a{
    color: var(--black-700);
}

html[data-theme=light-theme] .signinup-group-checkmark label a:hover{
    color: var(--black);
}

html[data-theme=light-theme] .signinup-group-checkmark label .checkmark-icon{
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}

html[data-theme=light-theme] .signinup-group-checkmark input:checked + label{
    color: var(--dark3);
}

html[data-theme=light-theme] .signinup-group-checkmark input:checked + label .checkmark-icon{
    border-color: var(--emerald-green3);
    background-color: var(--emerald-green3);
}
/* --- Remember input checkbox start --- */


/* --- ---- */
html[data-theme=light-theme] .signinup-group .sg-list-bx{
    border: 1px solid var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item{
    color: var(--slate-grey4);
    font-weight: 500;
}

html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item:hover{
    color: var(--dark3);
}

html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item.active:hover,
html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item.active{
    color: var(--white);
    background-color: var(--emerald-green2);
}

/* -=-=-=-=-=-=-=-=- */

html[data-theme=light-theme] .signinup-group .sg-list-bx.slb-style-2{
    border: none;
    background-color: transparent;
}

html[data-theme=light-theme] .signinup-group .sg-list-bx.slb-style-2 .sg-list-item{
    border: 1px solid var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .signinup-group .sg-list-bx .sg-list-item:hover{
    color: var(--black);
    border-color: var(--black-200);
}

html[data-theme=light-theme] .signinup-group .sg-list-bx.slb-style-2 .sg-list-item.active{
    color: var(--white);
    border-color: var(--emerald-green2);
    background-color: var(--emerald-green2);
}

/* --- --- */


/* --- Input autofill start --- */
html[data-theme=light-theme] .signinup-group select:-webkit-autofill,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-group select:-webkit-autofill:active,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:hover,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:focus,
html[data-theme=light-theme] .signinup-group input:-webkit-autofill:active {
    caret-color: var(--black) !important;
    color: var(--black) !important;
    -webkit-text-fill-color: var(--black) !important;
    -webkit-box-shadow: inset 0 0 0 100px rgb(240 240 240) !important;
            box-shadow: inset 0 0 0 100px rgb(240 240 240) !important;
}
/* --- Input autofill end --- */


/* --- login & submit btn start --- */
html[data-theme=light-theme] .common-submit-btn.csb-outline{
    background-color: var(--white);
    border-color: var(--emerald-green2);
    color: var(--emerald-green2);
}

html[data-theme=light-theme] .common-submit-btn.csb-outline:hover{
    color: var(--white);
}
/* --- login & submit btn end --- */


/* --- Common file upload start --- */
html[data-theme=light-theme] .fileupload{
    border-color: var(--black-060);
    background-color: var(--black-040);
}

html[data-theme=light-theme] .fileupload .feud-lable{
    color: var(--black-700);
    font-weight: 500;
}

html[data-theme=light-theme] .fileupload .feud-sub-lable{
    font-weight: 500;
    color: var(--dark3);
}

html[data-theme=light-theme] .fileupload .feud-lable span{
    color: var(--emerald-green3);
}

html[data-theme=light-theme] .fileupload.dragover,
html[data-theme=light-theme] .fileupload:hover{
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}

html[data-theme=light-theme] .fileupload:hover .feud-lable{
    color: var(--dark3);
}
/* --- Common file upload end --- */


/* --- Common file upload item start --- */
html[data-theme=light-theme] .upload-document-item{
    border: 1px solid var(--black-060);
}

html[data-theme=light-theme] .upload-document-item .udi-img-bx{
    background-color: var(--snow-gray);
}

html[data-theme=light-theme] .upload-document-item .udi-data-bx .udi-data-name{
    color: var(--dark3);
}

html[data-theme=light-theme] .upload-document-item .udi-data-bx .udi-data-type-size{
    color: var(--slate-grey4);
}

html[data-theme=light-theme] .upload-document-item .udi-icon-bx{
    color: var(--black-700);
}
/* --- Common file upload item end --- */


/* -=-=-=-=-=-=-=-=-=-=-= */


/* --- Form white start --- */
html[data-theme=light-theme] .sg-form-white .signinup-group select,
html[data-theme=light-theme] .sg-form-white .signinup-group textarea,
html[data-theme=light-theme] .sg-form-white .signinup-group input {
    border-color: var(--black2-060);
    background-color: var(--white);
}

html[data-theme=light-theme] .sg-form-white .signinup-group-checkmark label .checkmark-icon{
    border-color: var(--black-100);
    background-color: var(--white);
}

/* --- Input autofill start --- */
html[data-theme=light-theme] .sg-form-white .signinup-group select:-webkit-autofill,
html[data-theme=light-theme] .sg-form-white .signinup-group select:-webkit-autofill:hover,
html[data-theme=light-theme] .sg-form-white .signinup-group select:-webkit-autofill:focus,
html[data-theme=light-theme] .sg-form-white .signinup-group select:-webkit-autofill:active,
html[data-theme=light-theme] .sg-form-white .signinup-group input:-webkit-autofill,
html[data-theme=light-theme] .sg-form-white .signinup-group input:-webkit-autofill:hover,
html[data-theme=light-theme] .sg-form-white .signinup-group input:-webkit-autofill:focus,
html[data-theme=light-theme] .sg-form-white .signinup-group input:-webkit-autofill:active {
    -webkit-box-shadow: inset 0 0 0 100px var(--white) !important;
            box-shadow: inset 0 0 0 100px var(--white) !important;
}
/* --- Input autofill end --- */
/* --- Form white end --- */

/* --- Commonform css end --- */



/* --- signinup start --- */

/* --- Smb content (signinup header) start --- */
html[data-theme=light-theme] .signinup-logo-bx img.logo-text{
    filter: invert(1);
}
/* --- Smb content (signinup header) end  --- */

/* --- Smb content (Screen heading) start --- */
html[data-theme=light-theme] .screen-hed {
    color: var(--dark3);
}

html[data-theme=light-theme] .screen-pera {
    color: var(--black);
}
/* --- Smb content (Screen heading) start --- */

/* --- signinup end --- */



/* --- Pattern css start --- */
html[data-theme=light-theme] .gradient-background-bx{
    opacity: 0.5;
}
/* --- Pattern css end --- */
