/* --- Common field update start --- */
:root {
    --PhoneInputCountryFlag-height: 1em;
    --PhoneInputCountryFlag-aspectRatio: 1.5;
}


/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0;
    display: none;
}

::placeholder{
    font-size: 14px;
    color: var(--grayish-blue);
    font-weight: 400;
    font-family: "Inter", sans-serif;
}

select {
    -webkit-appearance: none;
    -moz-appearance: none;
    cursor: pointer;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}
/* --- Common field update end --- */


/* --- Extra label start --- */
.sgr-outer-label{
    color: var(--white-700);
    font-size: 12px;
    font-weight: 500;
    margin-bottom: 8px;
}

.sgr-outer-label.sol-star::after{
    content: "*";
    margin-left: 5px;
    position: relative;
    top: 0px;
    font-size: 14px;
    color: var(--emerald-green2);
}
/* --- Extra label end --- */


.signinup-group {
    position: relative;
    margin: 0px 0 18px;
}


.signinup-group select,
.signinup-group textarea,
.signinup-group input {
    font-family: "Inter", sans-serif;
    font-weight: 400;
    display: block;
    color: var(--white);
    border: 0;
    font-size: 14px;
    border: 1px solid var(--charcoal-grey);
    background-color: var(--dark);
    width: 100%;
    line-height: 20px;
    outline: none;
    padding: 10px 12px;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    -webkit-transition: all 0.3s ease;
    -o-transition: all 0.3s ease;
    -moz-transition: all 0.3s ease;
    transition: all 0.3s ease;
}

.gopageinput.signinup-group input{
    height: auto;
    padding: 4px 3px;
    width: 46px;
    text-align: center;
}

.signinup-group select[disabled],
.signinup-group textarea[disabled],
.signinup-group input[disabled] {
    cursor: not-allowed;
    pointer-events: none;
}

.signinup-group textarea{
    min-height: 80px;
}

.signinup-group.sg-150-textarea textarea{
    min-height: 150px;
}


/* .signinup-group:not(.error-group) select:hover,
.signinup-group:not(.error-group) textarea:hover,
.signinup-group:not(.error-group) input:hover{
    border-color: var(--black);
    box-shadow: 0 0 0.1px 1px var(--black);
} */

/* .signinup-group textarea:not(:placeholder-shown), */
.signinup-group textarea:focus,
/* .signinup-group select:not(:placeholder-shown), */
.signinup-group select:focus,
/* .signinup-group input:not(:placeholder-shown), */
.signinup-group input:focus {
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}



.signinup-group.sgl-icon select,
.signinup-group.sgl-icon input{
    padding-left: 44px;
}

.signinup-group.sgr-icon select,
.signinup-group.sgr-icon input{
    padding-right: 44px;
}

.signinup-group .group_right_icon,
.signinup-group .group_left_icon{
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 42px;
    width: 42px;
    font-size: 14px;
    color: var(--white);
    font-weight: 500;
}

.signinup-group .group_left_icon{
    position: absolute;
    left: 0;
    top: 0;
}

.signinup-group .group_right_icon{
    position: absolute;
    right: 0;
    top: 0;
}

.signinup-group .group_right_icon svg,
.signinup-group .group_left_icon svg{
    height: 22px;
    width: 22px;
    opacity: .7;
    transition: all .3s ease-in-out;
}

.signinup-group .group_left_icon svg{
    opacity: 1;
}

.signinup-group.sgl-sg3 svg{
    color: var(--slate-grey3);
}

.signinup-group .group_right_icon.gri-select-icon{
    pointer-events: none;
}

.signinup-group .group_right_icon.gri-select-icon svg{
    height: 20px;
    width: 20px;
}

.signinup-group:hover .group_right_icon.gri-select-icon svg,
.signinup-group .group_right_icon:hover svg,
.signinup-group .group_left_icon:hover svg{
    opacity: 1;
}



/* --- Error input color start --- */
.signinup-group.error-group textarea,
.signinup-group.error-group select,
.signinup-group.error-group input{
    border-color: var(--red-color);
    box-shadow: 0 0 0px 1px var(--red-color);
}
/* --- Error input color end --- */



/* --- Form error msg start --- */
.form-error-msg{
    display: block;
    text-align: right;
    color: var(--red-color);
    font-size: 12px;
    font-weight: 400;
    margin-bottom: -5px;
    margin-top: 8px;
    letter-spacing: 0.5px;
}

.form-error-msg .fa{
    margin-right: 5px;
}

.form-error-msg::before{
    display: none;
    content: "\f071";
    font: normal normal normal 14px/1 FontAwesome;
    margin-right: 5px;
    font-size: 10px;
}
/* --- Form error msg end --- */



/* --- Basic input note start --- */
.input-note{
    font-weight: 400;
    color: var(--white-700);
    font-size: 14px;
    margin-top: 10px;
}

.input-note span{
    cursor: pointer;
    font-weight: 500;
    color: var(--white);
    transition: all 0.3s ease-in-out;
}

.input-note span:hover{
    color: var(--emerald-green3);
    text-decoration: underline;
}

.input-note svg{
    width: 24px;
    height: 24px;
    position: relative;
    top: -1.5px;
    color: var(--emerald-green3);
}
/* --- Basic input note end --- */


/* --- Remember input checkbox start --- */
.signinup-group-checkmark input{
    display: none;
}

.signinup-group-checkmark{
    margin-bottom: 13px;
    position: relative;
    z-index: 3;
}

.signinup-group-checkmark label{
    display: flex;
    gap: 6px;
    cursor: pointer;
    font-size: 14px;
    color: var(--white-700);
    font-weight: 400;
    transition: all 0.3s ease-in-out;
}


.signinup-group-checkmark:not(.sgc-ci-hover-off) label:hover{
    color: var(--white);
}

.signinup-group-checkmark label a{
    color: var(--white-700);
    text-decoration: underline;
}

.signinup-group-checkmark label a:hover{
    color: var(--white);
}

.signinup-group-checkmark label .checkmark-icon{
    cursor: pointer;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 4px;
    background-position: center;
    border: 1px solid var(--charcoal-grey);
    background-color: var(--dark);
    box-shadow: 0 0 0px 4px transparent inset;
    background-repeat: no-repeat;
    background-position: center;
    background-size: calc(100% - 2px);
    transition: 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

.signinup-group-checkmark.sgc-ci-18px label{
    font-size: 13px;
}
.signinup-group-checkmark.sgc-ci-18px label .checkmark-icon{
    width: 18px;
    height: 18px;
}

.signinup-group-checkmark label:hover .checkmark-icon{
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}

.signinup-group-checkmark input:checked + label{
    color: var(--white);
}

.signinup-group-checkmark input:checked + label .checkmark-icon{
    border-color: var(--emerald-green3);
    background-color: var(--emerald-green3);
    box-shadow: none;
    background-image: url(../img/icons/check.svg);
}
/* --- Remember input checkbox start --- */



/* --- ---- */
.signinup-group .sg-list-bx{
    border-radius: 8px;
    border: 1px solid var(--charcoal-grey);
    background-color: var(--dark);
    padding: 4px;
    display: flex;
    align-items: center;
    max-width: fit-content;
    width: 100%;
    gap: 8px;
    overflow: hidden;
    overflow-x: auto;
}

.signinup-group .sg-list-bx .sg-list-item{
    position: relative;
    color: var(--white-700);
    font-size: 14px;
    font-weight: 400;
    padding: 8px 8px;
    cursor: pointer;
    border-radius: 6px;
    letter-spacing: 0.5px;
    transition: all 0.3s ease-in-out;
}

.signinup-group .sg-list-bx .sg-list-item img{
    background-color: var(--white);
    border-radius: 8px;
    width: 80px;
    height: 50px;
    object-position: center;
    object-fit: contain;
}

.signinup-group .sg-list-bx .sg-list-item:hover{
    color: var(--white);
}

.signinup-group .sg-list-bx .sg-list-item.active{
    color: var(--white);
    background-color: var(--emerald-green2);
}

/* -=-=-=-=-=-=-=-=- */

.signinup-group .sg-list-bx.slb-style-2{
    border-radius: 0;
    border: none;
    background-color: transparent;
    padding: 0;
    flex-wrap: wrap;
    gap: 8px;
    overflow: hidden;
    overflow-x: auto;
}

.signinup-group .sg-list-bx.slb-style-2 .sg-list-item{
    border-radius: 8px;
    border: 1px solid var(--charcoal-grey);
    background-color: var(--dark);
    padding: 8px 10px;
    line-height: 1.4;
    text-transform: capitalize;
    font-weight: 500;
}

.signinup-group .sg-list-bx .sg-list-item:hover{
    color: var(--white);
    border-color: var(--white-300);
}

.signinup-group .sg-list-bx.slb-style-2 .sg-list-item.active{
    color: var(--white);
    border-color: var(--emerald-green2);
    background-color: var(--emerald-green2);
}

/* -=-=-=-=-=-=-=-=- */

.signinup-group .sg-list-bx.slb-img .sg-list-item{
    padding: 2px 2px;
}

/* --- --- */



/* --- phone-number-input start --- */
.sg-country-code .PhoneInputCountry {
    height: 43px;
    left: 10px;
    position: absolute;
    top: 0;
}

.PhoneInputCountryIcon {
    height: 1em;
    height: var(--PhoneInputCountryFlag-height);
    width: 1.5em;
    width: calc(var(--PhoneInputCountryFlag-height)* var(--PhoneInputCountryFlag-aspectRatio));
}

.PhoneInputCountrySelect {
    border: 0;
    cursor: pointer;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: 1;
}
.PhoneInputCountryIconImg {
    display: block;
    height: 100%;
    width: 100%;
}

.sg-country-code .PhoneInputCountrySelectArrow {
    border-width: 0 1.5px 1.5px 0;
    left: 3px;
    padding: 3px;
    position: relative;
    top: -2px;
}

.sg-country-code input {
    padding-left: 58px;
}

.PhoneInputInput {
    flex: 1 1;
    min-width: 0;
}

.PhoneInputCountrySelectArrow {
    border-bottom-width: 1px;
    border-color: currentColor;
    border-left-width: 0;
    border-right-width: 1px;
    border-style: solid;
    border-top-width: 0;
    content: "";
    display: block;
    height: .3em;
    margin-left: .35em;
    opacity: .45;
    transform: rotate(45deg);
    width: .3em;
}

.PhoneInputCountry {
    align-items: center;
    align-self: stretch;
    display: flex;
    margin-right: .35em;
    position: relative;
}
/*--- Phone number input end ---*/



/* --- Input autofill start --- */
.signinup-group select:-webkit-autofill,
.signinup-group select:-webkit-autofill:hover,
.signinup-group select:-webkit-autofill:focus,
.signinup-group select:-webkit-autofill:active,
.signinup-group input:-webkit-autofill,
.signinup-group input:-webkit-autofill:hover,
.signinup-group input:-webkit-autofill:focus,
.signinup-group input:-webkit-autofill:active {
    font-weight: 400 !important;
    font-size: 14px !important;
    caret-color: var(--white) !important;
    color: var(--white) !important;
    -webkit-text-fill-color: var(--white) !important;
    -webkit-box-shadow: inset 0 0 0 100px var(--dark) !important;
            box-shadow: inset 0 0 0 100px var(--dark) !important;
}
/* --- Input autofill end --- */



/* --- login & submit btn start --- */
.common-submit-btn,
.lsb-loader-btn{
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
       -moz-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
    -webkit-align-items: center;
       -moz-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 9px 15px;
    -webkit-border-radius: 8px;
       -moz-border-radius: 8px;
            border-radius: 8px;
    width: 100%;
    font-weight: 500;
    color: var(--white);
    font-size: 16px;
    margin: 0 auto 13px;
    position: relative;
    border: none;
    outline: none;
    cursor: pointer;
    background-color: var(--emerald-green3);
    max-width: 450px;
    width: 100%;
    letter-spacing: 0.5px;
}

.common-submit-btn.csb-width-fit{
    max-width: fit-content;
    width: 100%;
    padding: 9px 25px;
}

.common-submit-btn.csb-width-full,
.lsb-loader-btn.csb-width-full{
    max-width: 100%;
}

.common-submit-btn.csb-outline{
    background-color: var(--dark2);
    color: var(--emerald-green3);
    border: 1px solid var(--emerald-green3);
    overflow: hidden;
    transition: all 0.3s ease-in-out;
}

.common-submit-btn.csb-gradient:after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    background: url(/src/img/icons/pattern/btn-pattern.png), linear-gradient(90deg, #33A57F 0%, #1DF8B0 100%);
    transition: all 0.3s ease-in-out;
    z-index: 0;
}

.common-submit-btn.csb-gradient span{
    z-index: 1;
}

.common-submit-btn.csb-gradient:hover{
    color: var(--white);
}

.common-submit-btn.csb-gradient:hover::after{
    opacity: 1;
}

.common-submit-btn[disabled]{
    background-color: #7c868e;
}
/* --- login & submit btn end --- */


/* --- lsb loader btn start --- */
.lsb-loader-btn img{
    pointer-events: none;
    width: 18px;
    height: 18px;
    margin-right: 7px;
    position: relative;
    margin-right: 8px;
    -webkit-animation: rotation-btn 1s infinite linear;
       -moz-animation: rotation-btn 1s infinite linear;
         -o-animation: rotation-btn 1s infinite linear;
            animation: rotation-btn 1s infinite linear;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    -moz-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
}

@-webkit-keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}

@-moz-keyframes rotation-btn {
    from {
        -moz-transform: rotate(0deg);
             transform: rotate(0deg);
    }

    to {
        -moz-transform: rotate(360deg);
             transform: rotate(360deg);
    }
}

@-o-keyframes rotation-btn {
    from {
        -o-transform: rotate(0deg);
           transform: rotate(0deg);
    }

    to {
        -o-transform: rotate(360deg);
           transform: rotate(360deg);
    }
}

@keyframes rotation-btn {
    from {
        -webkit-transform: rotate(0deg);
           -moz-transform: rotate(0deg);
             -o-transform: rotate(0deg);
                transform: rotate(0deg);
    }

    to {
        -webkit-transform: rotate(360deg);
           -moz-transform: rotate(360deg);
             -o-transform: rotate(360deg);
                transform: rotate(360deg);
    }
}
/* --- lsb loader btn end --- */


/* --- Common file upload start --- */
.fileupload{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding: 30px 10px;
    width: 100%;
    min-height: 100px;
    border-radius: 10px;
    background-color: var(--dark);
    border: 1px dashed var(--charcoal-grey);
    transition: all 0.3s ease-in-out;
    cursor: pointer;
}

.fileupload .feud-img{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.fileupload svg{
    width: 50px;
    height: 50px;
    opacity: 0.5;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-lable{
    color: var(--white-700);
    font-weight: 400;
    font-size: 14px;
    margin-top: 15px;
    margin-bottom: 5px;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.fileupload .feud-sub-lable{
    font-weight: 400;
    color: var(--white);
    font-size: 12px;
}

.fileupload .feud-lable span{
    color: var(--emerald-green5);
    text-decoration: underline;
}

.fileupload.dragover,
.fileupload:hover{
    border-style: solid;
    border-color: var(--emerald-green3);;
    box-shadow: 0 0 0px 1px var(--emerald-green3);;
}

.fileupload.dragover *{
    pointer-events: none
}

.fileupload.dragover .feud-img,
.fileupload.dragover svg,
.fileupload:hover svg,
.fileupload:hover .feud-img{
    opacity: 1;
}

.fileupload:hover .feud-lable{
    color: var(--white);
}
/* --- Common file upload end --- */


/* --- Common file upload item start --- */
.upload-document-item{
    display: flex;
    align-items: center;
    padding: 8px;
    gap: 10px;
    border-radius: 8px;
    border: 1px solid var(--charcoal-grey);
}

.upload-document-item .udi-img-bx{
    width: 60px;
    height: 60px;
    flex: 0 0 60px;
    background-color: var(--dark);
    border-radius: 8px;
    overflow: hidden;
}

.upload-document-item .udi-img-bx img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.upload-document-item .udi-data-bx{
    flex: auto;
}

.upload-document-item .udi-data-bx .udi-data-name{
    font-size: 14px;
    font-weight: 500;
    color: var(--white);
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-overflow: ellipsis;
    overflow: hidden;
    word-break: break-word;
}

.upload-document-item .udi-data-bx .udi-data-type-size{
    font-weight: 500;
    color: var(--white-700);
    font-size: 12px;
    margin-top: 3px;
}

.upload-document-item .udi-icon-bx{
    cursor: pointer;
    position: relative;
    color: var(--white-700);
    display: flex;
    justify-content: center;
    align-items: center;
    width: 46px;
    height: 46px;
    flex: 0 0 46px;
    padding: 10px;
    border-radius: 50%;
    transition: all 0.1s ease-in-out;
}

.upload-document-item .udi-icon-bx::after{
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 46px;
    height: 46px;
    border-radius: 50%;
    background-color:var(--red2-color);
    transform: scale(0);
    transform-origin: center;
    opacity: 0;
    visibility: hidden;
    transition: all 0.1s ease-in-out;
}

.upload-document-item .udi-icon-bx:active,
.upload-document-item .udi-icon-bx:hover{
    color: var(--white);
}

.upload-document-item .udi-icon-bx:hover::after{
    opacity: 1;
    visibility: visible;
    transform: scale(1);
}

.upload-document-item .udi-icon-bx svg{
    position: relative;
    z-index: 1;
}

.upload-document-item .udi-icon-bx.udi-delete:active,
.upload-document-item .udi-icon-bx.udi-delete:hover{
    color: var(--red-color);
}
/* --- Common file upload item end --- */



/* --- Signinup group switche start ---  */
.signinup-group-switche{
    position: relative;
    background-color: var(--graphite-grey);
    border-radius: 50px;
    height: 20px;
    width: 34px;
    flex: 0 0 34px;
    box-shadow: 0 0 0 1px var(--graphite-grey);
    transition: all 0.3s linear !important;
    cursor: pointer;
}

.signinup-group-switche .sgs-inner{
    position: absolute;
    left: calc(0px + 2px);
    top: 50%;
    transform: translateY(-50%);
    width: 16px;
    height: 16px;
    padding: 2px;
    border-radius: 50%;
    color: var(--graphite-grey);
    background-color: var(--white);
    transition: all 0.1s linear !important;
    display: flex;
    justify-content: center;
    align-items: center;
}

.signinup-group-switche .sgs-inner svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

@media screen and (min-width:768px) {   
    .signinup-group-switche{
        opacity: 0.9;
    }

    .signinup-group-switche:hover{
        opacity: 1;
    }
}

.signinup-group-switche.active{
    color: var(--emerald-green);
    box-shadow: 0 0 0 1px var(--emerald-green);
    background-color: var(--emerald-green);
}

.signinup-group-switche.active .sgs-inner{
    color: var(--emerald-green);
    background-color: var(--white);
    left: calc(100% - 0px - 18px);
}
/* --- Signinup group switche end ---  */



/* --- Form white start --- */
.sg-form-white ::placeholder{
    color: var(--slate-grey3);
}


.sg-form-white .signinup-group select,
.sg-form-white .signinup-group textarea,
.sg-form-white .signinup-group input {
    color: var(--black);
    border: 1px solid var(--white);
    background-color: var(--white);
}


.sg-form-white .signinup-group textarea:focus,
.sg-form-white .signinup-group select:focus,
.sg-form-white .signinup-group input:focus {
    border-color: var(--emerald-green3);
    box-shadow: 0 0 0px 1px var(--emerald-green3);
}

.sg-form-white .sgr-outer-label{
    color: var(--white);
    margin-bottom: 4px;
}

.sg-form-white .signinup-group .group_right_icon,
.sg-form-white .signinup-group .group_left_icon{
    color: var(--black);
}

.sg-form-white .signinup-group .group_right_icon svg,
.sg-form-white .signinup-group .group_left_icon svg{
    height: 24px;
    width: 24px;
}

.sg-form-white .signinup-group-checkmark label .checkmark-icon{
    background-color: var(--black-400);
}

.sg-form-white .PhoneInputCountrySelectArrow{
    border-color: var(--black);
}

.sg-form-white .form-error-msg{
    font-weight: 800;
}

/* --- Input autofill start --- */
.sg-form-white .signinup-group select:-webkit-autofill,
.sg-form-white .signinup-group select:-webkit-autofill:hover,
.sg-form-white .signinup-group select:-webkit-autofill:focus,
.sg-form-white .signinup-group select:-webkit-autofill:active,
.sg-form-white .signinup-group input:-webkit-autofill,
.sg-form-white .signinup-group input:-webkit-autofill:hover,
.sg-form-white .signinup-group input:-webkit-autofill:focus,
.sg-form-white .signinup-group input:-webkit-autofill:active {
    caret-color: var(--black) !important;
    color: var(--black) !important;
    -webkit-text-fill-color: var(--black) !important;
    -webkit-box-shadow: inset 0 0 0 100px var(--white) !important;
            box-shadow: inset 0 0 0 100px var(--white) !important;
}
/* --- Input autofill end --- */

/* --- Form white end --- */
