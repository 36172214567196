/* --- --- */
.pattern-bx{
    position: fixed;
    right: 0;
    bottom: 0;
    width: 100%;
    height: calc(100% - 70px);
    overflow: hidden;
    pointer-events: none;
    transition: all 0.3s ease-in-out;
}

.pattern-bx.pb-full{
    width: 100%;
    height: 100%;
}

@media screen and (min-width: 1200px) {   
    .pattern-bx{
        width: calc(100% - 240px);
    }
    .custome-wrapper.cw-smb-mini .pattern-bx{
        width: calc(100% - 75px);
    }
}
/* --- --- */

/* --- --- */
.line-pattern{
    pointer-events: none;
    position: absolute;
    width: 140px;
    opacity: 0.7;
}

.line-pattern.lp-tr{
    right: 0;
    top: -70px;
}

.line-pattern.lp-bl{
    bottom: -70px;
    left: 0px;
}
/* --- --- */


/* --- Gradient background start --- */
.gradient-background-bx {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    pointer-events: none;
}

.gb-inner {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    -webkit-filter: blur(100px);
    filter: blur(100px);
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}

.gb-shape {
    mix-blend-mode: lighten;
    -webkit-animation-duration: 20s;
    animation-duration: 20s;
    position: absolute;
    border-radius: 100%;
    -webkit-animation-iteration-count: infinite;
    animation-iteration-count: infinite;
    -webkit-animation-timing-function: cubic-bezier(0.1, 0, 0.9, 1);
    animation-timing-function: cubic-bezier(0.1, 0, 0.9, 1);
}


.gb-shape.gb-s-2 {
    top: 50%;
    left: 50%;
    width: 420px;
    height: 420px;
    background-image: radial-gradient(var(--emerald-green), var(--emerald-green-100));
    transform: translate(-50%, -50%);
    animation-name: gbsAnimation2;
}


@-webkit-keyframes gbsAnimation2 {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(-20deg);
        transform: translate(-50%, -50%) rotate(-20deg);
    }

    20% {
        -webkit-transform: translate(-50%, -50%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(-50%, -50%) skew(-15deg, -15deg) rotate(80deg);
    }

    40% {
        -webkit-transform: translate(-50%, -50%) rotate(180deg);
        transform: translate(-50%, -50%) rotate(180deg);
    }

    60% {
        -webkit-transform: translate(-50%, -50%) skew(15deg, 15deg) rotate(80deg);
        transform: translate(-50%, -50%) skew(15deg, 15deg) rotate(80deg);
    }

    80% {
        -webkit-transform:translate(-50%, -50%) rotate(180deg);
        transform:translate(-50%, -50%) rotate(180deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(340deg);
        transform: translate(-50%, -50%) rotate(340deg);
    }
}

@keyframes gbsAnimation2 {
    0% {
        -webkit-transform: translate(-50%, -50%) rotate(-20deg);
        transform: translate(-50%, -50%) rotate(-20deg);
    }

    20% {
        -webkit-transform: translate(-50%, -50%) skew(-15deg, -15deg) rotate(80deg);
        transform: translate(-50%, -50%) skew(-15deg, -15deg) rotate(80deg);
    }

    40% {
        -webkit-transform: translate(-50%, -50%) rotate(180deg);
        transform: translate(-50%, -50%) rotate(180deg);
    }

    60% {
        -webkit-transform: translate(-50%, -50%) skew(15deg, 15deg) rotate(80deg);
        transform: translate(-50%, -50%) skew(15deg, 15deg) rotate(80deg);
    }

    80% {
        -webkit-transform:translate(-50%, -50%) rotate(180deg);
        transform:translate(-50%, -50%) rotate(180deg);
    }

    100% {
        -webkit-transform: translate(-50%, -50%) rotate(340deg);
        transform: translate(-50%, -50%) rotate(340deg);
    }
}
/* --- Gradient background end --- */