:root{
    --white: #ffffff;
    --white-900: rgba(255, 255, 255, 0.9);
    --white-800: rgba(255, 255, 255, 0.8);
    --white-700: rgba(255, 255, 255, 0.7);
    --white-600: rgba(255, 255, 255, 0.6);
    --white-500: rgba(255, 255, 255, 0.5);
    --white-400: rgba(255, 255, 255, 0.4);
    --white-300: rgba(255, 255, 255, 0.3);
    --white-200: rgba(255, 255, 255, 0.2);
    --white-100: rgba(255, 255, 255, 0.1);

    --black: #000000;
    --black-900: rgba(0, 0, 0, 0.9);
    --black-800: rgba(0, 0, 0, 0.8);
    --black-700: rgba(0, 0, 0, 0.7);
    --black-600: rgba(0, 0, 0, 0.6);
    --black-500: rgba(0, 0, 0, 0.5);
    --black-400: rgba(0, 0, 0, 0.4);
    --black-300: rgba(0, 0, 0, 0.3);
    --black-200: rgba(0, 0, 0, 0.2);
    --black-100: rgba(0, 0, 0, 0.1);
    --black-060: rgba(0, 0, 0, 0.06);
    --black2-060: #f0f0f0;
    --black-040: rgba(0, 0, 0, 0.04);
    --black2-040: #f5f5f5;


    --light-s-gray: #f3f4f6;

    
    --red-color: #F63D68;
    --red2-color: #FFE4E8;

    --green-color: #16B364;
    --green2-color: #D3F8DF;
    --green3-color: #2F534A;
    --green4-color: #BFE1D8;

    --yellow-color: #CA8504;
    --yellow2-color: #FEF7C3;

    
    
    /* --- Dark mode color --- */
    --dark: #070F0E;
    --dark2: #0C1413;

    --dark3: #0F111E;
    --dark3-700: rgba(15, 17, 31, 0.7);

    --dark4: #18191C;
    --dark-grey: #2a2a2a;
    --quartz: #4C4A54;

    --snow-gray: #F7F7F9;
    --snow-gray2: #E8E8E8;
    --snow-gray3: #E5E5E5;
    
    --teal-blue: #337388;
    --emerald-green: #33B17B;
    --emerald-green-500: rgba(51, 177, 122, 0.5);
    --emerald-green-100: rgba(51, 177, 122, 0.1);
    --emerald-green2: #57B596;
    --emerald-green3: #37B793;
    --emerald-green4-500: #69B9A580;
    --emerald-green5: #53E2B3;
    --emerald-green6: #039A7C;
    --aqua-mint: #52E8BE;
    --aqua-mint2: #10FFE3;
    --aqua-mint3: #5DCFDA;
    --grayish-blue: #D4D7E0;

    --mantis:#79C239;
    --pistachio:#72C141; 

    --coral-orange: #FF6127;
    --charcoal-purple: #403C48;
    --majestic-purple: #6938EF;

    --mint-green-100: #E7FEF81A;

    --pale-steel: #CCD2DD;
    
    --charcoal-grey: #2E2C33;  /* #3b3b3b */
    --charcoal-grey2: #2C342E;
    --shadow-grey: #2B2933;
    --slate-grey: #888E9E;
    --slate-grey2: #6B788E;
    --slate-grey3: #68656F;
    --slate-grey4: #565656;
    --graphite-grey: #323741;
    --silver-grey: #bcbcbe;
    --dim-grey: #6B6B6B;
    --blue-gray: #243757;
    --grayish-blue: #B3B9C4;

    --lavender-mist: #eaedf2;
    --lavender-mist-700: rgba(233, 236, 241, 0.7);
    --lavender-mist-100: rgba(233, 236, 241, 0.1);


    --raven-black: #151419;
    --onyx-black: #1A191E; 
    --eclipse-black: #181818;

}