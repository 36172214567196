/* --- Side Menu Bx Css Start --- */
.side-menu-bx{
    position: fixed;
    top: 0px;
    left: 0;
    z-index: 126;
    width: 240px;
    height: 100%;
    background-color: var(--dark);
    border-right: 1px solid var(--charcoal-grey);
    transition: 0.3s ease-in-out;
}

.side-menu-bx.active {
    opacity: 1;
    visibility: visible;
    transform: translateX(0%);
}


/* --- */
.side-menu-bx .smb-header{
    position: sticky;
    top: 0;
    z-index: 1;
    height: 70px;
    padding: 16px 16px;
    background-color: var(--dark);
}

.side-menu-bx .smb-header .smb-logo{
    display: flex;
    align-items: center;
    gap: 10px;
}

.side-menu-bx .smb-header .smb-logo .logo-icon{
    position: relative;
    right: 0;
    width: 34px;
    height: 34px;
    flex: 0 0 34px;
    transition: right 0.3s ease-in-out;
}

.side-menu-bx .smb-header .smb-logo .logo-text{
    width: 130px;
    height: 20px;
    object-position: left center;
    object-fit: contain;
    transition: opacity 0.3s ease-in-out;
}
/* --- */


/* --- */
.smb-menu-bx{
    position: absolute;
    top: 0;
    padding: 16px;
    padding-top: 2px;
    height: calc(100% - 70px);
    max-height: calc(100% - 70px);
    width: 100%;
    margin-top: 70px;
    overflow: auto;
    transition: .3s ease-in-out;
}

.smb-menu-bx::-webkit-scrollbar{
    display: none;
}

.side-menu-bx .smb-label{
    font-size: 12px;
    font-weight: 500;
    color: var(--slate-grey);
    margin-bottom: 10px;
}

.side-menu-bx .smb-link{
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    border-radius: 10px;
    color: var(--lavender-mist-700);
    font-weight: 300;
    font-size: 14px;
    padding: 0px 12px;
    margin-bottom: 5px;
    height: 40px;
    transition: color 0.3s ease-in-out, background-color 0.3s ease-in-out;
    letter-spacing: 1px;
}

.side-menu-bx .smb-link .smb-icon{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
    flex: 0 0 20px;
}

.side-menu-bx .smb-link .smb-icon svg{
    width: 100%;
    height: 100%;
    object-fit: contain;
    object-position: center;
}

.side-menu-bx .smb-link .smb-text{
    white-space: nowrap;
    line-height: 1;
}

.side-menu-bx .smb-link .smb-text{
    position: relative;
    top: 0.5px;
}

.side-menu-bx .smb-link:hover{
    color: var(--lavender-mist);
    background-color: var(--lavender-mist-100);
}

.side-menu-bx .smb-link.active{
    color: var(--white);
    background: radial-gradient(54.5% 251.15% at 50% 90.27%, var(--dim-grey) 0%, var(--raven-black) 100%);
    box-shadow: 0 0 0 1px var(--charcoal-grey);
}

.side-menu-bx .smb-link.highlight-link{
    gap: 4px;
    font-weight: 600;
    justify-content: center;
    color: var(--onyx-black);
    background: white;
    letter-spacing: initial;
    overflow: hidden;
}

.side-menu-bx .smb-link.highlight-link:hover{
    color: var(--white);
    background: var(--emerald-green2);
}

.side-menu-bx .smb-link-separator{
    height: 15px;
}
/* --- */

/* --- */
.side-menu-bx .smb-footer{
    position: absolute;
    left: 0;
    bottom: 0;
    padding: 16px;
    width: 100%;
    margin: 0;
    background-color: var(--dark);
    overflow: hidden;
    display: none;
}

.side-menu-bx .smb-f-user{
    width: 100%;
    padding: 12px;
    border-radius: 12px;
    box-shadow: 0 0 0 1px var(--charcoal-grey);
    background: linear-gradient(90deg, #212027 0%, transparent 100%);
    display: flex;
    gap: 10px;
    transition: all 0.3s ease-in-out;
    margin-bottom: 20px;
}

.side-menu-bx .smb-footer .smb-f-user{
    margin-bottom: 0px;
}

.side-menu-bx .smb-f-user .sfu-img{
    width: 42px;
    height: 42px;
    flex: 0 0 42px;
    border-radius: 50%;
    overflow: hidden;
    box-shadow: 0 0 0 1px var(--charcoal-grey);
}

.side-menu-bx .smb-f-user .sfu-img img{
    width: 100%;
    height: 100%;
    object-position: center;
    object-fit: cover;
}

.side-menu-bx .smb-f-user .sfu-data .sfu-name{
    font-size: 14px;
    font-weight: 500;
    color: white;
}

.side-menu-bx .smb-f-user .sfu-data .sfu-other{
    font-size: 12px;
    font-weight: 400;
    color: var(--slate-grey);
}
/* --- */

/* --- Side Menu Bx Css End --- */


/* --- Side Menu Overlay Start --- */
.side-menu-overlay{
    position: fixed;
    left: 0;
    top: 0;
    inset: 0;
    z-index: 125;
    background-color: var(--black-300);
    backdrop-filter: blur(5px);
    opacity: 0;
    visibility: hidden;
    transition: all 0.3s ease-in-out;
}
/* --- Side Menu Overlay End --- */



/* --- side menu min css start --- */
@media screen and (min-width:1200px) {

    .side-menu-bx.smb-mini:hover{
        z-index: 128;
    }

    .side-menu-bx.smb-mini:not(:hover){
        width: 75px;
    }

    .side-menu-bx.smb-mini:not(:hover) .smb-header .smb-logo .logo-icon{
        right: -5px;
    }

    .side-menu-bx.smb-mini:not(:hover) .smb-footer .smb-f-user .sfu-data,
    .side-menu-bx.smb-mini:not(:hover) .smb-header .smb-logo .logo-text,
    .side-menu-bx.smb-mini:not(:hover) .smb-link .smb-text{
        opacity: 0;
        visibility: hidden;
    }

    .side-menu-bx.smb-mini .smb-link.highlight-link{
        justify-content: flex-start;
    }

    .side-menu-bx .smb-menu-bx{
        height: calc(100% - 70px - 96px);
        max-height: calc(100% - 70px - 96px);
        padding-top: 16px;
    }
    
    .side-menu-bx.smb-mini:not(:hover) .smb-footer .smb-f-user{
        box-shadow: none;
        background: transparent;
        padding-left: 0;
        padding-right: 0;
    }

    .side-menu-bx .smb-footer{
        display: block;
    }
    .side-menu-bx .smb-menu-bx .smb-f-user{
        display: none;
    }
}
/* --- side menu min css end --- */

/* --- side menu min css end --- */
@media screen and (max-width:1200px) and (min-width:0px) {
    .side-menu-bx{
        opacity: 0;
        visibility: hidden;
        transform: translateX(-100%);
    }

    .side-menu-bx.active {
        opacity: 1;
        visibility: visible;
        transform: translateX(0%);
    }

    .side-menu-overlay.active{
        opacity: 1;
        visibility: visible;
    }

    .side-menu-bx .smb-link .smb-text{
        position: relative;
        top: 1px;
    }

    .side-menu-bx .smb-link.highlight-link{
        color: var(--white);
        background: var(--emerald-green2);
    }
    
}
/* --- side menu min css end --- */